<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="users"
            :show-labels="false"
            :hide-custom-filters="true"
            :filters="filteringPanel.filters"
            :loaded="filteringPanel.loaded"
            @search="getData"
            @change="filterData"
            :load-dictionaries="loadDictionaries"
            @dict-loaded="onFilteringPanelLoad"
          />
        </b-col>
      </b-row>
      <b-row align-h="between">
        <b-col lg="1">
          <b-button-group>
            <b-button
              variant="outline-dark"
              title="Add user"
              @click="addUser()"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-card-body>
        <table-custom
          ref="users"
          :name="`${$customTable.getCustomTableName($route.meta.module)}`"
          :loading="dataTable.isLoading"
          :data="dataTable.dataSet"
          :options="dataTable.options"
        >
          <div slot="custom-actions" slot-scope="props">
            <div class="btn-group">
              <div class="btn-group">
                <span
                  class="btn btn-primary btn-sm"
                  @click="viewUser(props.row.id)"
                >
                  <font-awesome-icon icon="eye"
                /></span>
                <span
                  class="btn btn-success btn-sm"
                  @click="editUser(props.row.id)"
                >
                  <font-awesome-icon icon="pencil-alt"
                /></span>

                <span
                  v-if="props.row.Status === 'Active'"
                  class="btn btn-danger btn-sm"
                  @click="deleteUser(props.row.id)"
                >
                  <font-awesome-icon icon="trash" />
                </span>

                <span
                  v-if="props.row.Status === 'Inactive'"
                  class="btn btn-warning btn-sm"
                  @click="restoreUser(props.row.id)"
                >
                  <font-awesome-icon icon="trash-restore"
                /></span>

                <span
                  class="btn btn-info btn-sm"
                  @click="migrateUser(props.row.id)"
                >
                  <b-spinner
                    v-if="props.row.isStatisticsLoading"
                    small
                    type="grow"
                  />
                  <font-awesome-icon
                    v-if="!props.row.isStatisticsLoading"
                    icon="angle-double-right"
                  />
                </span>
              </div>
            </div>
          </div>
        </table-custom>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import FilteringPanel from "@/components/FilteringPanel";
import TableCustom from "@/components/TableCustom";

export default {
  name: "Overview",
  components: {
    FilteringPanel,
    TableCustom
  },
  data() {
    return {
      isLoading: false,
      filteringPanel: {
        selected: {},
        loaded: false,
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            name: "umng_user_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            name: "umng_user_status",
            trackby: "id",
            label: "label",
            options: [
              { id: "Active", label: "Active" },
              { id: "Inactive", label: "Inactive" }
            ],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Category",
            name: "umng_user_category",
            trackby: "id",
            label: "label",
            options: [
              { id: "Exempt", label: "Exempt" },
              { id: "Hourly", label: "Hourly" },
              { id: "None", label: "None" }
            ],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Emp Status",
            name: "umng_emp_status",
            trackby: "id",
            label: "label",
            options: [
              { id: "Full time", label: "Full time" },
              { id: "Part time", label: "Part time" },
              { id: "None", label: "None" }
            ],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            title: "Role",
            name: "umng_role",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          }
        ]
      },
      rawData: [],
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterable: [
            "ID",
            "Name",
            "user_name",
            "Email",
            "Status",
            "Role",
            "Groups",
            "Category",
            "EmpStatus"
          ],
          columns: [
            "ID",
            "Name",
            "user_name",
            "Email",
            "Status",
            "Role",
            "Groups",
            "Category",
            "EmpStatus",
            "Date Birth",
            "Actions"
          ],

          //showActions: true,
          perPage: 50,
          showCustomActions: true,
          showChildRows: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    this.getData();
  },
  methods: {
    async loadDictionaries() {
      let self = this;

      const roles = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "umng_role")) {
          this.$api.get("roles").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "umng_role"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.rolename
              }))
            ];
          });
        }
      };

      const users = async () => {
        if (
          this.filteringPanel.filters.find(f => f.name === "umng_user_name")
        ) {
          this.$api.get("dictionaries/users").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "umng_user_name"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ];
          });
        }
      };

      Promise.all([users(), roles()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    async onFilteringPanelLoad() {
      await this.getData();

      this.filterData(this.$refs.filteringPanel.selected);
    },
    onFilter() {},
    filterData(e) {
      this.filteringPanel.selected = e;

      let selected = this.filteringPanel.selected;

      if (!this.rawData.length) return;

      let filteredData = this.rawData;

      filteredData = filteredData.filter(i =>
        selected.umng_user_name && selected.umng_user_name.length > 0
          ? selected.umng_user_name.find(s => s.label === i["Name"])
          : true
      );
      filteredData = filteredData.filter(i =>
        selected.umng_user_status && selected.umng_user_status.length > 0
          ? selected.umng_user_status.find(s => s.label === i["Status"])
          : true
      );
      filteredData = filteredData.filter(i =>
        selected.umng_user_category && selected.umng_user_category.length > 0
          ? selected.umng_user_category.find(s => s.label === i["Category"])
          : true
      );
      filteredData = filteredData.filter(i =>
        selected.umng_emp_status && selected.umng_emp_status.length > 0
          ? selected.umng_emp_status.find(s => s.label === i["EmpStatus"])
          : true
      );
      filteredData = filteredData.filter(i =>
        selected.umng_role && selected.umng_role.length > 0
          ? selected.umng_role.find(s => s.label === i["Role"])
          : true
      );

      this.dataTable.dataSet = filteredData;
    },
    async getData() {
      await this.drawDataTable();
    },
    async drawDataTable() {
      this.dataTable.isLoading = true;

      let response = [];

      try {
        response = await this.$api.post(`users/all`);

        this.rawData = response;

        this.dataTable.isLoading = false;

        this.dataTable.dataSet = this.rawData;
      } catch (error) {
        console.log(error);

        this.dataTable.isLoading = false;

        this.$form.msgBoxOk("Error occured");
      }
    },

    addUser() {
      this.$router.push({
        name: "User submission",
        params: {
          action: "create"
        }
      });
    },
    viewUser(id) {
      this.$router.push({
        name: "User submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editUser(id) {
      this.$router.push({
        name: "User submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    async restoreUser(id) {
      let user = this.dataTable.dataSet.find(item => item["ID"] === id);

      let confirm = await this.showConfirmation(
        `User ${user.Name} will be restored. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      let data = {
        id: user.id,
        deleted: "0",
        status: "Active"
      };

      this.$api
        .put(`user/enable`, data)
        .then(() => {
          self.makeToast("Success", `User ${user.Name} restored`, "warning");

          self.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          self.makeToast("Error", response.data.message, "danger");
        });
    },
    async deleteUser(user_id) {
      let user = this.dataTable.dataSet.find(item => item.id === user_id);

      let confirm = await this.showConfirmation(
        `User ${user.Name} will be disabled. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      let data = {
        id: user.id,
        // deleted: '1',
        status: "Inactive"
      };

      this.$api
        .put(`user/disable`, data)
        .then(() => {
          self.makeToast("Success", `User ${user.Name} deleted`, "warning");

          self.drawDataTable();
        })
        .catch(response => {
          console.log(response);

          self.makeToast("Error", response.data.message, "danger");
        });
    },
    async migrateUser(user_id) {
      let userIdx = this.dataTable.dataSet.findIndex(
        item => item.id === user_id
      );

      let user = this.dataTable.dataSet[userIdx];

      user.isStatisticsLoading = true;
      Vue.set(this.dataTable.dataSet, userIdx, user);

      //if user has assigned entities
      const stats = await this.$api.get(`user/${user_id}/statistics`);

      user.isStatisticsLoading = false;

      Vue.set(this.dataTable.dataSet, userIdx, user);

      let statsMessage = "";

      if (stats.accounts !== "0")
        statsMessage += `Accounts: ${stats.accounts}\n`;
      if (stats.contacts !== "0")
        statsMessage += `Contacts: ${stats.contacts}\n`;
      // if (stats.activities !== '0') statsMessage += `Activities: ${stats.activities}\n`
      if (stats.barrels !== "0") statsMessage += `Barrels: ${stats.barrels}\n`;
      if (stats.sample_requests !== "0")
        statsMessage += `Sample requests: ${stats.sample_requests}\n`;
      if (stats.custom_filters !== "0")
        statsMessage += `Custom filters: ${stats.custom_filters}\n`;
      if (stats.projects !== "0")
        statsMessage += `Projects: ${stats.projects}\n`;
      if (stats.tasks !== "0 / 0") statsMessage += `Tasks: ${stats.tasks}\n`;
      if (stats.territories !== "0")
        statsMessage += `Territories: ${stats.territories}\n`;
      if (stats.report_categories !== "0 / 0")
        statsMessage += `Report categories: ${stats.report_categories}\n`;

      if (statsMessage !== "") {
        let res = await this.$form.showConfirmation(
          `${user.Name} has following linked objects:\n\n${statsMessage}\nDo you want to reassign them to another user?`
        );

        if (!res) return;

        this.$router.push({
          name: "Migration manager",
          params: {
            userId: user_id
          }
        });

        return;
      }

      if (statsMessage === "") {
        await this.$form.msgBoxOk(`${user.Name} has no linked objects`);

        return;
      }
    },
    makeToast(title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        autoHideDelay: 3000,
        solid: true
      });
    },
    showConfirmation(message) {
      return this.$bvModal.msgBoxConfirm(message, {
        title: "Confirm action",
        size: "md",
        buttonSize: "md",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      });
    },
    applyFilters() {
      /*
      this.dataTable.dataSet = this.userData.filter(
        item => item.Status === this.toolbar.userStatus.value
      );

      if (this.toolbar.salaryCategory.value) {
        this.dataTable.dataSet = this.dataTable.dataSet.filter(
          item => item.Category === this.toolbar.salaryCategory.value
        );
      }

      if (this.toolbar.empStatus.value) {
        this.dataTable.dataSet = this.dataTable.dataSet.filter(
          item => item.EmpStatus === this.toolbar.empStatus.value
        );
      }
      */
    }
  },
  watch: {}
};
</script>

<style>
.modal-body {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.fa-toolbar {
  color: #0000008a;
}

.img-avatar {
  height: 35px;
  max-width: 100%;
  border-radius: 50em;
}

/*
tr td:nth-child(2) {
    width: 50px;
}*/
</style>
