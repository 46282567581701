var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "users",
      "show-labels": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad
    }
  })], 1)], 1), _c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add user"
    },
    on: {
      "click": function click($event) {
        return _vm.addUser();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)], 1)], 1), _c('b-card-body', [_c('table-custom', {
    ref: "users",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewUser(props.row.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editUser(props.row.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), props.row.Status === 'Active' ? _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteUser(props.row.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e(), props.row.Status === 'Inactive' ? _c('span', {
          staticClass: "btn btn-warning btn-sm",
          on: {
            "click": function click($event) {
              return _vm.restoreUser(props.row.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash-restore"
          }
        })], 1) : _vm._e(), _c('span', {
          staticClass: "btn btn-info btn-sm",
          on: {
            "click": function click($event) {
              return _vm.migrateUser(props.row.id);
            }
          }
        }, [props.row.isStatisticsLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !props.row.isStatisticsLoading ? _c('font-awesome-icon', {
          attrs: {
            "icon": "angle-double-right"
          }
        }) : _vm._e()], 1)])])]);
      }
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }